import isPropValid from '@emotion/is-prop-valid';
import moment from 'moment';

const currentLocalTime = offset => {
  const d = new Date();
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const nd = new Date(utc + 3600000 * offset);

  // the current date needs to match the format of the event date for comparing
  return moment(nd).format('DD.MM.YYYY, h:mm A');
};

/**
 * isValidProp
 * utility checker which uses `isPropValid` function
 * function is skipping checking if the prop === 'element', otherwise it
 * runs through `isPropValid`.
 */
export const isValidProp = prop => (prop === 'element' ? true : isPropValid(prop));

export const rem = px => `${px / 16}rem`;

export const shuffleArray = array => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

/**
 * isLive
 * accepts a start date for a program, and checks to see if
 * the current CET time is later than the program start date (up to the minute)
 * returns true if the program is currently live, otherwise returns false
 */
export const isLive = programDate => {
  // get current CET time
  const currentCETTime = currentLocalTime('+2');

  return programDate <= currentCETTime;
};

/**
 * isCompleted
 * accepts an end date for a program, and checks to see if
 * the current CET time is later than the program end date (up to the minute)
 * returns true if the program has already completed, otherwise returns false
 */
export const isCompleted = presentationEnd => {
  // get current CET time
  const currentCETTime = currentLocalTime('+2');

  return presentationEnd <= currentCETTime;
};
