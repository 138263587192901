// Naming convention → https://material.io/tools/color/

const color = {
  white: '#fff',
  black: '#000',

  // Green shades
  tealAccent400: '#34D5AF',

  // Blue shades
  blueGray50: '#f6f8fa',
  blueGray600: '#6C7889',
  blue900: '#1558D6',

  // Accent
  redAccent700: '#d50000',
};

const theme = {
  name: 'light',

  scaffolding: {
    color: color.black,
    background: color.blueGray50,
    selection: color.tealAccent400,
  },

  font: {
    text: '"Hind Madurai", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    display: 'Merriweather, Georgia, sans-serif',
    size: {
      mini: 14,
      default: 16,
      medium: 20,
    },
    color: {
      brand: color.tealAccent400,
      dark: color.black,
      light: color.white,
    },
    heading: {
      h1: {
        fontSize: 50,
        fontSizeSmall: 32,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: 32,
        fontSizeSmall: 24,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: 24,
        fontSizeSmall: 20,
        lineHeight: 1.5,
      },
      h4: {
        fontSize: 16,
        fontSizeSmall: 16,
        lineHeight: 1.5,
      },
    },
  },

  grid: {
    breakpoint: {
      // Phones
      toPhoneSmall: '@media screen and (max-width: 23.43625em)', // <374.98px
      fromPhoneSmall: '@media screen and (min-width: 23.4375em)', // >375px

      fromPhoneMedium: '@media screen and (min-width: 36em)', // > 576px
      phoneMedium: '@media screen and (min-width: 36em) and (max-width: 52.12375em)', // 576px, 833.98px
      toPhoneMedium: '@media screen and (max-width: 35.99875em)', // < 575.98px

      phones: '@media screen and (max-width: 52.12375em)', // 833.98px

      // Tablets
      fromTablet: '@media screen and (min-width: 52.125em)', // > 834px
      tablets: '@media screen and (min-width: 52.125em) and (max-width: 61.87375em)', // 834px, 989.98px
      toTablet: '@media screen and (max-width: 61.87375em)', // < 989.98px

      // Desktops
      fromDesktop: '@media screen and (min-width: 61.875em)', // 990px
    },
    container: {
      default: '960px',
    },
  },

  spacing: {
    micro: 25,
    mini: 50,
    default: 75,
    medium: 100,
    large: 125,
    mega: 150,
  },

  color,
};

export default theme;
