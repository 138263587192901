import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const baseStyles = css`
  max-width: 300px;
  margin: 0 auto 30px;
`;

/**
 * Component style declarations
 */
export const TextStyles = styled('p')(baseStyles);
