import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  padding: 80px 0 550px;
  background: ${color.black};
  ${breakpoint.fromTablet} {
    min-height: 100vh;
  }
  ${breakpoint.fromDesktop} {
    padding: 150px 0 80px;
  }
`;

const contentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-columns: auto;

  ${breakpoint.fromTablet} {
    grid-template-columns: 1fr 700px 1fr;
  }
`;

const headerStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  text-align: center;
  ${breakpoint.fromTablet} {
    grid-column: 2;
  }
`;

const footerLogoStyles = () => css`
  display: block;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
`;

const taglineStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
}) => css`
  font-size: ${rem(28)};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  display: block;
  color: ${font.color.light};
  margin-top: 150px;
  ${breakpoint.fromPhoneMedium} {
    margin-top: 250px;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  position: absolute;
  right: 0;
  top: 550px;
  left: 0;
  ${breakpoint.fromDesktop} {
    right: -100px;
    top: 10px;
    left: initial;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Content = styled('div')(contentStyles);
export const Header = styled('header')(headerStyles);
export const FooterLogo = styled('img')(footerLogoStyles);
export const Tagline = styled('span')(taglineStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
