import { css } from '@emotion/core';
import theme from './theme';
import { rem } from './utils';

const {
  font,
  font: { size },
  grid: { breakpoint },
} = theme;

// Typography
export const fontColor = kind => css`
  color: ${font.color[kind]};
`;

export const fontWeight = kind => css`
  font-family: ${font[kind]};
`;

export const fontSizeLead = css`
  font-size: ${rem(size.large)};
  ${breakpoint.tablets} {
    font-size: ${rem(size.large - 4)};
  }
  ${breakpoint.phones} {
    font-size: ${rem(size.medium + 4)};
  }
`;

// Margins
const margin = direction => value => css`
  ${`margin-${direction}: ${value}px`}
`;

export const mt = margin('top');
export const mr = margin('right');
export const mb = margin('bottom');
export const ml = margin('left');

const responsiveMargin = direction => screenSizes => values => {
  const arr = [];

  screenSizes.forEach((screenSize, index) => {
    arr.push(`
      ${`
      ${breakpoint[screenSize]} {
        margin-${direction}: ${values[index]}px
      }`}
    `);
  });

  return arr
    .toString()
    .split(',')
    .join(' ');
};

export const respMT = responsiveMargin('top');
export const respMR = responsiveMargin('right');
export const respMB = responsiveMargin('bottom');
export const respML = responsiveMargin('left');
export const mrla = css`
  margin-right: auto;
  margin-left: auto;
`;
