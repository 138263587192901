import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = css`
  display: grid;
  grid-gap: 0 15px;
`;

const gridKindStyles = ({
  gridKind,
  theme: {
    grid: { breakpoint },
  },
}) => {
  switch (gridKind) {
    case 'half':
      return css`
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        ${breakpoint.fromPhoneSmall} {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }
        ${breakpoint.fromPhoneMedium} {
          grid-template-columns: repeat(2, 1fr);
        }
      `;
    case 'third':
      return css`
        grid-template-columns: 1fr;
        ${breakpoint.fromPhoneMedium} {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }
        ${breakpoint.fromDesktop} {
          grid-template-columns: repeat(3, 1fr);
        }
      `;
    case 'default':
      break;
  }
};

/**
 * Component style declarations
 */
export const GridStyles = styled('div')(baseStyles, gridKindStyles);
