import React from 'react';
import { Wrapper, List, Item } from './Sponsors.style';
import one from '../../images/sponsor-01.svg';
import two from '../../images/sponsor-02.png';
import three from '../../images/sponsor-03.svg';
import four from '../../images/sponsor-04.png';
import five from '../../images/sponsor-05.png';
import six from '../../images/sponsor-06.png';
import seven from '../../images/sponsor-07.svg';
import eight from '../../images/sponsor-08.png';
import nine from '../../images/sponsor-09.jpg';

const Sponsors = () => (
  <Wrapper>
    <List>
      <Item>
        <img src={one} />
      </Item>
      <Item>
        <img src={two} />
      </Item>
      <Item>
        <img src={three} />
      </Item>
      <Item>
        <img src={four} />
      </Item>
      <Item>
        <img src={five} />
      </Item>
      <Item>
        <img src={six} />
      </Item>
      <Item>
        <img src={seven} />
      </Item>
      <Item>
        <img src={eight} />
      </Item>
      <Item>
        <img src={nine} />
      </Item>
    </List>
  </Wrapper>
);

export default Sponsors;
