import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link as GatsbyLink } from 'gatsby';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  background: ${color.black};
  border-top: 2px solid ${color.white};
  border-bottom: 2px solid ${color.white};
  padding: 100px 0 50px;
  ${breakpoint.fromTablet} {
    padding: 200px 0 100px;
  }
`;

const listStyles = ({
  isNewsPage,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  list-style-type: none;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 40.625em) {
    grid-gap: 0 15px;
    grid-template-columns: ${isNewsPage ? `repeat(2, 1fr)` : `repeat(3, 1fr)`};
  }
  ${breakpoint.fromTablet} {
    grid-gap: 0 30px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const listItemStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-bottom: 30px;
  ${breakpoint.fromTablet} {
    margin-bottom: 60px;
  }
`;

const linkStyles = ({ theme: { color } }) => css`
  color: ${color.white};
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 30px;
  display: inline-block;
  &:hover,
  &:active,
  &:visited {
    color: ${color.white};
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const List = styled('ul')(listStyles);
export const ListItem = styled('li')(listItemStyles);
export const Link = styled(GatsbyLink)(linkStyles);
