import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from '../../utils/utils';

// 2 button variations
const wrapperStyles = ({ buttonSize, buttonKind, theme: { color, font } }) => css`
  font-size: ${rem(font.size.default)};
  font-family: ${font.text};
  color: ${buttonKind === 'dark' ? font.color.light : font.color.dark};
  border: 2px solid;
  border-color: ${buttonKind === 'dark' ? font.color.light : font.color.dark};
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  display: inline-block;
  cursor: pointer;
  outline: none;
  position: relative;
  background: ${buttonKind === 'dark' ? color.black : color.white};
  padding: ${buttonSize === 'default' ? '14px 20px' : '10px 15px'};
`;

/**
 * Component style declarations
 */
export const ButtonWrapper = styled('button')(wrapperStyles);
