import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../../utils/utils';
import DefaultButton from '../../Button';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-columns: auto;
  position: relative;
  width: 100%;
  ${breakpoint.fromTablet} {
    grid-gap: 30px;
    grid-template-columns: 1fr 40%;
  }
`;

const imageContainerStyles = ({ isCompleted }) =>
  isCompleted &&
  css`
    opacity: 0.7;
  `;

const buttonStyles = ({ isLive, theme: { color } }) =>
  isLive &&
  css`
    color: ${color.white};
    background: ${color.tealAccent400};
    border-color: ${color.tealAccent400};
    font-weight: 500;
  `;

const liveButtonStyles = ({ theme: { color } }) => css`
  position: absolute;
  padding: 0 9px;
  display: flex;
  align-items: center;
  font-weight: 500;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid ${color.white};
  z-index: 1;
  &:before {
    content: '•';
    margin-right: 4px;
    font-size: ${rem(40)};
    color: ${color.redAccent700};
    position: relative;
    top: 3px;
  }
`;

const sateliteLabelStyles = ({ theme: { color } }) => css`
  position: absolute;
  color: ${color.white};
  z-index: 1;
  display: block;
  padding: 6px 9px;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.4);
  img {
    width: 14px;
    margin-right: 6px;
    position: relative;
    top: 1px;
  }
`;

const liveSateliteLabelStyles = ({ isLive, isCompleted }) =>
  isLive &&
  !isCompleted &&
  css`
    top: 65px;
  `;

const cardWrapperStyles = ({
  theme: {
    font: { color },
  },
}) => css`
  margin-left: 0;
  background: ${color.light};
  display: flex;
  flex-direction: column;
`;

const headerStyles = ({ theme: { color } }) => css`
  padding: 15px;
  background: ${color.tealAccent400};
`;

const completedHeaderStyles = ({ isCompleted, theme: { color } }) =>
  isCompleted &&
  css`
    background: ${color.blueGray600};
    opacity: 0.6;
  `;

const titleStyles = ({
  theme: {
    font: { size, color },
  },
}) => css`
  color: ${color.light};
  font-size: ${rem(size.medium)};
  line-height: 1;
  margin: 0;
`;

const dateStyles = ({
  theme: {
    font: { size, color },
  },
}) => css`
  font-weight: 700;
  font-size: ${rem(size.medium)};
  color: ${color.light};
  display: block;
  margin-bottom: 20px;
`;

const presenterListStyles = css`
  padding-left: 0;
  list-style-type: none;

  li {
    display: inline-block;
  }
`;

const presenterStyles = ({
  theme: {
    font: { color },
  },
}) => css`
  font-weight: 400;
  color: ${color.light};
  margin: 0 4px 0 0;
  display: inline-block;
`;

const descriptionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  a:first-of-type {
    margin-right: 15px;
    ${breakpoint.toPhoneSmall} {
      margin: 0 0 15px;
      display: block;
    }
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const ImageContainer = styled('div')(imageContainerStyles);
export const SateliteLabel = styled('span')(sateliteLabelStyles, liveSateliteLabelStyles);
export const Button = styled(DefaultButton)(buttonStyles);
export const LiveButton = styled(DefaultButton)(liveButtonStyles);
export const Description = styled('div')(descriptionStyles);
export const CardWrapper = styled('div')(cardWrapperStyles);
export const Title = styled('h2')(titleStyles);
export const Date = styled('time')(dateStyles);
export const Header = styled('header')(headerStyles, completedHeaderStyles);
export const PresenterList = styled('ul')(presenterListStyles);
export const Presenter = styled('h4')(presenterStyles);
