import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Wrapper } from '../Cards.style';

const wrapperStyles = css`
  min-height: 390px;
  display: flex;
  flex-direction: column;
`;

const bodyStyles = css`
  padding: 0 15px 15px;
`;

const footerStyles = css`
  margin-top: auto;
  display: flex;
  padding: 0 15px 15px;
  justify-content: space-between;

  a {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const externalIconStyles = css`
  width: 18px;
  position: relative;
  top: 3px;
  margin-top: -2px;
`;

/**
 * Component style declarations
 */
export const CardWrapper = styled(Wrapper)(wrapperStyles);
export const Description = styled('div')(bodyStyles);
export const Footer = styled('footer')(footerStyles);
export const ExternalIcon = styled('img')(externalIconStyles);
