import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isValidProp } from '../../utils/utils';
import { baseStyles, kindStyles, sizeStyles } from './Heading.style';

/**
 * Heading component
 */
const Heading = styled(({ element, children, ...props }) => React.createElement(`${element}`, props, children), {
  shouldForwardProp: isValidProp,
})(baseStyles, kindStyles, sizeStyles);

Heading.propTypes = {
  children: PropTypes.node,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  colorKind: PropTypes.oneOf(['dark', 'light']),
  family: PropTypes.oneOf(['text', 'display']),
};

Heading.defaultProps = {
  children: null,
  colorKind: 'dark',
  family: 'display',
};

export default Heading;
