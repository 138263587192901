import React from 'react';
import PropTypes from 'prop-types';
import { GridStyles } from './Grid.style';

/**
 * Grid component
 */
const Grid = ({ gridKind, ...props }) => <GridStyles gridKind={gridKind} {...props} />;

Grid.propTypes = {
  gridKind: PropTypes.oneOf(['half', 'third']),
};

Grid.defaultProps = {
  gridKind: 'third',
};

export default Grid;
