import styled from '@emotion/styled';
import { css } from '@emotion/core';

// Contain 16:9 aspect ratio for the <iframe> element.
// For 4:3 aspect ratio padding-botton of should be set to 75%.
const wrapperStyles = ({ aspectRatio }) => css`
  position: relative;
  padding-bottom: ${aspectRatio === '16-9' ? '56.25%' : '75%'};
  padding-top: 35px;
  height: 0;
  overflow: hidden;
`;

const iframeStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const IFrame = styled('iframe')(iframeStyles);
