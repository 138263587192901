import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { ButtonWrapper } from './Button.style';

/**
 * Button component
 */
const ButtonComponent = ({ onClick, title, buttonKind, buttonSize, ...props }) => (
  <ButtonWrapper onClick={onClick} buttonKind={buttonKind} buttonSize={buttonSize} {...props}>
    {title}
  </ButtonWrapper>
);

const Button = ({ to, onClick, title, buttonKind, buttonSize, ...props }) => {
  const isMailLink = RegExp(/mailto:/).test(to);
  const isExternalLink = RegExp(/^https?:\/\//).test(to);

  if (!to) {
    return <ButtonComponent onClick={onClick} title={title} buttonKind={buttonKind} buttonSize={buttonSize} />;
  } else if (to && !isMailLink && !isExternalLink) {
    return (
      <GatsbyLink to={to}>
        <ButtonComponent onClick={onClick} title={title} buttonKind={buttonKind} buttonSize={buttonSize} />
      </GatsbyLink>
    );
  } else {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <ButtonComponent onClick={onClick} title={title} buttonKind={buttonKind} buttonSize={buttonSize} {...props} />
      </a>
    );
  }
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  buttonKind: PropTypes.oneOf(['dark', 'light']),
  buttonSize: PropTypes.oneOf(['default', 'mini']),
};

ButtonComponent.defaultProps = {
  onClick: () => {},
  title: null,
  buttonKind: 'dark',
  buttonSize: 'default',
};

Button.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  buttonKind: PropTypes.oneOf(['dark', 'light']),
  buttonSize: PropTypes.oneOf(['default', 'mini']),
};

Button.defaultProps = {
  to: undefined,
  onClick: () => {},
  title: null,
  buttonKind: 'dark',
  buttonSize: 'default',
};

export default Button;
