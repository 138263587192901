/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Text, Button } from '../../components';
import { Wrapper, Content, Column, ImageWrapper, Title, BetterplaceLogo } from './Section07.style';
import betterPlaceLogo from '../../images/betterplace-logo.png';

const Section02 = () => {
  const data = useStaticQuery(graphql`
    query {
      section07Image: file(relativePath: { eq: "section-07.png" }) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Content>
          <Column index={1}>
            <ImageWrapper>
              <Img fluid={data.section07Image.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            </ImageWrapper>
          </Column>
          <Column index={2}>
            <header>
              <Title element="h2">About Kiron</Title>
              <Text>
                Kiron Open Higher Education (gGmbH) is a nonprofit that combines digital innovation and strategic
                partnerships to empower refugees worldwide and underserved communities in the Middle East through online
                learning opportunities.
              </Text>
            </header>
          </Column>
          <Column index={3}>
            <header>
              <Title element="h2">Support us</Title>
              <Text>
                Your donation will enable us to reach more refugees and underserved communities to actively participate
                in the conference.
              </Text>
              <BetterplaceLogo src={betterPlaceLogo} alt="Betterplace.org logo" />
              <Button to="https://www.betterplace.org/de/donate/platform/projects/36608" title="Donate now" />
            </header>
          </Column>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Section02;
