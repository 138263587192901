import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { Wrapper, List, ListItem, Dot, DotList, ArrowButton } from './components';
import useWindowSize from '../../utils/useWindowSize';

const splitNum = num => [...new Array(num)].map((_, index) => index + 1);
const LEFT = 'LEFT';
const RIGHT = 'RIGHT';

const Carousel = ({ slides }) => {
  const carouselItemArray = slides[0];
  const COUNT = carouselItemArray.length;

  const size = useWindowSize();
  const [displayedItemsCount, setDisplayedItemsCount] = useState(3);
  const dotItemsCount = Math.ceil(COUNT / displayedItemsCount);
  const dotList = splitNum(dotItemsCount);
  const [step, setStep] = useState(1);
  const [offset, setOffset] = useState(displayedItemsCount);

  useEffect(() => {
    if (size.width <= 650) {
      setDisplayedItemsCount(1);
    } else {
      setDisplayedItemsCount(3);
    }
  }, [size]);

  const handleSwipe = direction => {
    if (direction === LEFT) {
      if (step < dotItemsCount) {
        setStep(step + 1);
        setOffset((step + 1) * displayedItemsCount);
      }
    } else if (direction === RIGHT) {
      if (step > 1) {
        setStep(step - 1);
        setOffset((step - 1) * displayedItemsCount);
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(LEFT),
    onSwipedRight: () => handleSwipe(RIGHT),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...handlers}>
      <Wrapper>
        {step > 1 && <ArrowButton side="left" onClick={() => handleSwipe(RIGHT)} />}
        {step < COUNT / displayedItemsCount && <ArrowButton onClick={() => handleSwipe(LEFT)} />}
        <List>
          {carouselItemArray.map((num, index) => (
            <ListItem key={index} isVisible={index + 1 > offset - displayedItemsCount && index + 1 <= offset}>
              {num}
            </ListItem>
          ))}
        </List>
        {COUNT / displayedItemsCount > 1 && (
          <DotList>
            {dotList.map(num => (
              <Dot
                key={num}
                isActive={step === num}
                onClick={() => {
                  setStep(num);
                  setOffset(num * displayedItemsCount);
                }}
              />
            ))}
          </DotList>
        )}
      </Wrapper>
    </div>
  );
};

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
};

export default Carousel;
