import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

const wrapperStyles = ({ theme: { color } }) => css`
  background: ${color.tealAccent400};
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 15px;
  border-bottom: 2px solid ${color.white};
  border-top: 10px solid ${color.white};
`;

const contentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin: 0 auto;
  display: grid;
  ${breakpoint.fromPhoneMedium} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 15px;
    align-items: center;
    justify-content: space-between;
  }
  ${breakpoint.fromTablet} {
    grid-template-columns: 1fr 420px;
  }
`;

const logoWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: center;
  ${breakpoint.toPhoneMedium} {
    margin: 0 auto;
  }

  a {
    line-height: 1;
  }

  @media screen and (max-width: 25em) {
    .amplifyNowLogo {
      max-width: 150px;
    }
  }
`;

const logoSeparatorStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  width: 2px;
  background: #fff;
  height: 85px;
  margin: 0 15px;
  ${breakpoint.fromPhoneMedium} {
    margin: 0 30px;
  }
`;

const metaWrapperStyles = css`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

const dateWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-right: 20px;
  ${breakpoint.phones} {
    display: none;
  }
`;

const buttonWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.toPhoneMedium} {
    display: none;
  }
`;

const dateStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
}) => css`
  margin: 0;
  font-size: ${rem(24)};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 1.3;
  ${breakpoint.phones} {
    font-size: ${rem(font.size.default)};
  }
`;

const dateSubtitleStyles = ({ theme: { font } }) => css`
  margin: 0;
  font-size: ${rem(font.size.default)};
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.01em;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('header')(wrapperStyles);
export const Content = styled('div')(contentStyles);
export const MetaWrapper = styled('div')(metaWrapperStyles);
export const LogoWrapper = styled('div')(logoWrapperStyles);
export const LogoSeparator = styled('div')(logoSeparatorStyles);
export const DateWrapper = styled('div')(dateWrapperStyles);
export const ButtonWrapper = styled('div')(buttonWrapperStyles);
export const Date = styled('p')(dateStyles);
export const DateSubtitle = styled('p')(dateSubtitleStyles);
