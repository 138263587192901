import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import theme from '../../utils/theme';
import { scaffoldingStyles } from '../../utils/scaffolding';

const Page = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Global styles={scaffoldingStyles} />
        {children}
      </>
    </ThemeProvider>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
