import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({
  theme: {
    grid: { container, breakpoint },
  },
}) => css`
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: ${container.default};
  ${breakpoint.toTablet} {
    max-width: calc(100% - 30px);
  }
`;

/**
 * Component style declarations
 */
export const ContainerStyles = styled('div')(baseStyles);
