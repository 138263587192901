import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { KironLogo, Button } from '../../components';
import {
  Wrapper,
  Content,
  MetaWrapper,
  Date,
  DateSubtitle,
  DateWrapper,
  ButtonWrapper,
  LogoWrapper,
  LogoSeparator,
} from './MainNavigation.style';
import logo from '../../images/amplify-logo.svg';

const MainNavigation = () => (
  <Wrapper>
    <Content>
      <LogoWrapper>
        <GatsbyLink to="/">
          <img className="amplifyNowLogo" src={logo} alt="Logo of Virtual Refugee Conference" />
        </GatsbyLink>
        <LogoSeparator />
        <KironLogo withLink={false} />
      </LogoWrapper>
      <MetaWrapper>
        <DateWrapper>
          <Date>June 19-20 2020</Date>
          <DateSubtitle>Virtual Refugee Conference</DateSubtitle>
        </DateWrapper>
        <ButtonWrapper>
          <Button to="https://amplifynow.typeform.com/to/JgsqG9" title="Get involved" />
        </ButtonWrapper>
      </MetaWrapper>
    </Content>
  </Wrapper>
);

export default MainNavigation;
