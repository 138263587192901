import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading } from '../../components';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  background: ${color.tealAccent400};
  padding: 80px 0 100px;
  ${breakpoint.fromPhoneMedium} {
    padding: 250px 0 100px;
  }
`;

const contentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);

  ${breakpoint.fromPhoneMedium} {
    grid-template-rows: auto;
    align-items: center;
    grid-template-columns: 1fr 300px 1fr;
    grid-gap: 0 15px;
  }
`;

const columnStyles = ({
  index,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  grid-column: ${index === 1 ? 1 : index === 2 ? 2 : 3};
  grid-row: none;
  ${breakpoint.toPhoneMedium} {
    grid-column: auto;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.toPhoneMedium} {
    position: absolute;
    top: 440px;
    left: 0;
    right: 0;
  }
  ${breakpoint.fromPhoneMedium} {
    position: absolute;
    top: -50px;
    right: -30px;
    left: initial;
  }
  ${breakpoint.fromTablet} {
    right: 30px;
  }
`;

const titleStyles = css`
  margin: 0 0 20px;
`;

const impactSolutionsWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 100px 0 0;
  margin-top: 500px;
  ${breakpoint.fromPhoneMedium} {
    margin-top: 0;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Content = styled('div')(contentStyles);
export const Column = styled('div')(columnStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
export const Title = styled(Heading)(titleStyles);
export const ImpactSolutionsWrapper = styled('article')(impactSolutionsWrapperStyles);
