import { css } from '@emotion/core';
import { rem } from '../../utils/utils';

export const baseStyles = ({ family, theme: { font } }) => css`
  font-family: ${font[family]};
  font-weight: 900;
  margin-top: 0;
`;

export const kindStyles = ({ theme, colorKind }) =>
  colorKind &&
  css`
    color: ${theme.font.color[colorKind]};
  `;

export const sizeStyles = ({
  theme: {
    font,
    grid: { breakpoint },
  },
  element,
  size,
}) =>
  css`
    font-size: ${rem(font.heading[size || element].fontSize)};
    line-height: ${font.heading[size || element].lineHeight};
    ${breakpoint.toTablet} {
      font-size: ${rem(font.heading[size || element].fontSizeSmall)};
    }
  `;
