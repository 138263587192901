/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Heading, Text, Button, Grid } from '../../components';
import { introContainerStyles } from './Intro.style';

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "intro-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "intro-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 450, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 834.98px)`,
    },
  ];

  return (
    <Fragment>
      <Container>
        <Grid gridKind="half" css={introContainerStyles}>
          <div>
            <Img fluid={sources} style={{ maxWidth: 450, margin: '0 auto' }} imgStyle={{ objectFit: 'contain' }} />
          </div>
          <div>
            <Heading element="h1">Join the conversation</Heading>
            <Text>
              At <strong>Kiron</strong>, we believe that change can only happen when everyone’s voice is heard. That’s
              why we, along with our partners, are organising the first online conference dedicated to refugees around
              the world. Take part and join this community of changemakers on the 19th and 20th of June for meaningful
              discussions around this year’s theme:{' '}
              <strong>AMPLIFY NOW! Elevating new learning and strengthening communities.</strong>
            </Text>
            <Button to="/program" title="See program" />
          </div>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Intro;
