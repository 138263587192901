import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading } from '../../components';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 250px 0 80px;
  ${breakpoint.fromPhoneMedium} {
    padding: 250px 0 200px;
  }
  ${breakpoint.fromTablet} {
    padding: 250px 0;
  }
`;

const contentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-gap: 0 15px;
  ${breakpoint.phones} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
  ${breakpoint.fromTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
  ${breakpoint.toPhoneMedium} {
    grid-template-columns: auto;
  }
`;

const columnStyles = ({
  index,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.phones} {
    grid-column: ${index === 1 ? '1 / -1' : 'auto'};
  }
  ${breakpoint.toPhoneMedium} {
    &:first-of-type {
      display: none;
    }
  }

  p {
    font-size: 18px;
  }
`;

const imageWrapperStyles = css`
  max-width: 300px;
  margin: 0 auto;
`;

const titleStyles = css`
  margin: 0 0 10px;
`;

const logoStyles = css`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto 10px;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Content = styled('div')(contentStyles);
export const Column = styled('div')(columnStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
export const Title = styled(Heading)(titleStyles);
export const BetterplaceLogo = styled('img')(logoStyles);
