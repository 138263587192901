import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading } from '../../components';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  padding: 200px 0 60px;
  background: ${color.tealAccent400};
  ${breakpoint.fromTablet} {
    padding: 200px 0;
  }
`;

const columnStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  grid-column: 2;
  ${breakpoint.toTablet} {
    grid-column: 1 / -1;
  }
`;

const headerStyles = css`
  text-align: center;

  button {
    margin-top: 80px;
  }
`;

const titleStyles = css`
  margin: 0 auto 10px;
  max-width: 300px;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Column = styled('div')(columnStyles);
export const Header = styled('header')(headerStyles);
export const Title = styled(Heading)(titleStyles);
