import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { Header, Title, Copy, Hyperlink } from '../Cards.style';
import { Description, Footer, ExternalIcon, CardWrapper } from './ImpactSolutionCard.style';
import icon from '../../../images/icon-exit.svg';

const ImpactSolutionCard = ({ title, region, description, videoURL, slug, solutionURL }) => {
  const generateVideoLink = url => {
    const isYoutube = url.indexOf('?v=') > -1;

    if (isYoutube) {
      return `https://www.youtube.com/watch${url}`;
    } else {
      return `https://vimeo.com/${url}`;
    }
  };

  return (
    <CardWrapper>
      <Header>
        <Title element="h4" family="text">
          {title}
        </Title>
        <Copy>{region}</Copy>
      </Header>
      <Description>
        <Copy>
          {description.substring(0, 260)}...
          <GatsbyLink to={`/impact-solutions/${slug}`}> read more</GatsbyLink>
        </Copy>
      </Description>
      <Footer>
        {videoURL ? (
          <Hyperlink href={generateVideoLink(videoURL)} target="_blank" rel="noopener noreferrer">
            Video
          </Hyperlink>
        ) : null}
        {solutionURL ? (
          <Hyperlink href={solutionURL} target="_blank" rel="noopener noreferrer">
            Solution <ExternalIcon src={icon} alt="External link icon" />
          </Hyperlink>
        ) : null}
      </Footer>
    </CardWrapper>
  );
};

ImpactSolutionCard.propTypes = {
  title: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  videoURL: PropTypes.string,
  solutionURL: PropTypes.string,
};

ImpactSolutionCard.defaultProps = {
  videoURL: null,
  solutionURL: null,
};

export default ImpactSolutionCard;
