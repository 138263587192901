import React from 'react';
import PropTypes from 'prop-types';
import { ShareButtonStyles } from './Share.style';

const ButtonComponent = ({ onClick, icon }) => (
  <ShareButtonStyles onClick={onClick}>
    <img src={icon} alt={`${icon} icon`} />
  </ShareButtonStyles>
);

const ShareButton = ({ icon, onClick, to }) => {
  if (to) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <ButtonComponent onClick={onClick} icon={icon} />
      </a>
    );
  }

  return <ButtonComponent onClick={onClick} icon={icon} />;
};

ButtonComponent.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

ButtonComponent.defaultProps = {
  to: null,
  onClick: () => {},
  icon: PropTypes.string,
};

ShareButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

ShareButton.defaultProps = {
  to: null,
  onClick: () => {},
  icon: null,
};

export default ShareButton;
