import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, List, ListItem, ListItemTrigger, ShareButton } from './components';
import facebook from '../../images/icon-facebook.svg';
import linkedin from '../../images/icon-linkedin.svg';
// import messenger from '../../images/icon-messenger.svg';
import telegram from '../../images/icon-telegram.svg';
import twitter from '../../images/icon-twitter.svg';
import whatsup from '../../images/icon-whatsup.svg';
import share from '../../images/icon-share.svg';

const Share = ({ title, url }) => {
  const [isReavealed, setReveal] = useState(false);

  return (
    <Nav>
      <List>
        <ListItem isReavealed={isReavealed} count={1}>
          <ShareButton to={`https://telegram.me/share/url?url=${url}`} icon={telegram} />
        </ListItem>
        <ListItem isReavealed={isReavealed} count={2}>
          <ShareButton to={`whatsapp://send?text=${title}`} data-action="share/whatsapp/share" icon={whatsup} />
        </ListItem>
        <ListItem isReavealed={isReavealed} count={3}>
          <ShareButton to={`https://twitter.com/intent/tweet?text=${title}&url=${url}`} icon={twitter} />
        </ListItem>
        <ListItem isReavealed={isReavealed} count={4}>
          <ShareButton to={`https://www.facebook.com/sharer/sharer.php?u=${url}`} icon={facebook} />
        </ListItem>
        <ListItem isReavealed={isReavealed} count={5}>
          <ShareButton to={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} icon={linkedin} />
        </ListItem>
        <ListItemTrigger>
          <ShareButton icon={share} onClick={() => setReveal(!isReavealed)} />
        </ListItemTrigger>
      </List>
    </Nav>
  );
};

Share.defaultProps = {
  title: 'Virtual Refugee Conference',
  url: 'https://virtualrefugeeconference.com',
};

Share.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default Share;
