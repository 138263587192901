import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Description } from './ArticleCard.style';
import { Wrapper, Header, Title, Copy, Link } from '../Cards.style';

const ArticleCard = ({ title, category, shortDescription, image, slug }) => (
  <Wrapper>
    <Header>
      <Title element="h4" family="text">
        {title}
      </Title>
      <Copy>{category}</Copy>
    </Header>
    <Img alt={image.description} fluid={image.fluid} />
    <Description>
      <Copy>{shortDescription}</Copy>
      <Link to={`/news/${slug}`}>Read Now</Link>
    </Description>
  </Wrapper>
);

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default ArticleCard;
