import React from 'react';
import PropTypes from 'prop-types';
import { ArrowButtonStyles } from './Carousel.style';
import icon from '../../../images/icon-chevron-right.svg';

const ArrowButton = ({ side, onClick }) => (
  <ArrowButtonStyles side={side} onClick={onClick}>
    <img src={icon} alt="Chevron icon" />
  </ArrowButtonStyles>
);

ArrowButton.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
};

ArrowButton.defaultProps = {
  side: 'right',
  onClick: () => {},
};

export default ArrowButton;
