import { css } from '@emotion/core';
import theme from './theme';
import { rem } from './utils';
import normalize from './normalize.css';

const {
  scaffolding,
  font,
  font: { size },
  spacing,
  color,
} = theme;

export const scaffoldingStyles = css`
  ${normalize};
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-size: ${rem(size.default)};
    font-family: ${font.text};
    line-height: 1.5;
    color: ${scaffolding.color};
    background-color: ${scaffolding.background};
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures, contextual;
    font-variant-numeric: oldstyle-nums, proportional-nums;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    word-wrap: break-word;
    overflow-x: hidden;
    margin: 0;
    border-left: 10px solid ${color.white};
    border-right: 10px solid ${color.white};
  }

  ::-moz-selection {
    background: ${scaffolding.selection};
    color: ${scaffolding.color};
  }

  ::selection {
    background: ${scaffolding.selection};
    color: ${scaffolding.color};
  }

  p {
    font-size: ${rem(size.default)};
    margin: 0 0 ${rem(spacing.micro)};
  }

  strong {
    font-family: ${font.bold};
  }

  a {
    text-decoration: none;
    color: ${color.blue900};
  }

  .overflowYHidden {
    overflow-y: hidden;
  }
`;
