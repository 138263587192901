import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper, List, ListItem, Link } from './NewsSection.style';
import { Container, ArticleCard, Heading } from '../../components';

const NewsSection = () => {
  const data = useStaticQuery(graphql`
    query listQuery {
      allContentfulArticle(filter: {}, limit: 3, sort: { fields: publishDate, order: DESC }) {
        edges {
          node {
            id
            slug
            category
            title
            shortDescription
            image {
              description
              fluid(maxWidth: 350, resizingBehavior: SCALE) {
                src
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
        totalCount
      }
    }
  `);

  const articleArrWithTemplate = data.allContentfulArticle.edges;
  const totalCount = data.allContentfulArticle.totalCount;
  const articleArr = articleArrWithTemplate.filter(article => article.node.slug !== 'how-to-create-an-article');

  if (articleArr.length < 1) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Heading element="h2" colorKind="light">
          News
        </Heading>
        <List>
          {articleArr.map(item => (
            <ListItem key={item.node.id}>
              <ArticleCard
                slug={item.node.slug}
                category={item.node.category}
                title={item.node.title}
                shortDescription={item.node.shortDescription}
                image={item.node.image}
              />
            </ListItem>
          ))}
        </List>
        {totalCount > 3 && <Link to="/news">More Articles</Link>}
      </Container>
    </Wrapper>
  );
};

export default NewsSection;
