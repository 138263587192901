/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { mb, mrla } from '../../utils/classes';
import { Container, KironLogo } from '../../components';
import { Wrapper, Content, Header, FooterLogo, Tagline, ImageWrapper } from './Footer.style';
import logo from '../../images/amplify-logo-invert.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      section08Image: file(relativePath: { eq: "section-08.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Content>
          <Header>
            <FooterLogo src={logo} alt="Logo of Virtual Refugee Conference" css={mb(30)} />
            <KironLogo colorKind="light" size={200} css={mrla} />
            <Tagline>
              Virtual refugee conference <strong>June 19-20 2020</strong>
            </Tagline>
          </Header>
          <ImageWrapper>
            <Img fixed={data.section08Image.childImageSharp.fixed} />
          </ImageWrapper>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Footer;
