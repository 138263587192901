/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Text, Button } from '../../components';
import { Wrapper, Content, Column, ImageWrapper, Title } from './Section05.style';

const Section05 = () => {
  const dataOne = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "section-05.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktopImage: file(relativePath: { eq: "section-05.png" }) {
        childImageSharp {
          fixed(width: 450) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const sourcesOne = [
    {
      ...dataOne.mobileImage.childImageSharp.fixed,
      media: `(max-width: 834.98px)`,
    },
    {
      ...dataOne.desktopImage.childImageSharp.fixed,
      media: `(min-width: 834.98px)`,
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Content>
          <Column index={1}>
            <ImageWrapper>
              <Img fixed={sourcesOne} />
            </ImageWrapper>
          </Column>
          <Column index={2}>
            <header>
              <Title element="h2">Amplify your voice</Title>
              <Text>
                This World Refugee Day, discuss and debate the most important issues affecting you as a refugee, with
                other refugees, refugee-led organisations, activists and innovators around the world.{' '}
              </Text>
              <Text>
                {' '}
                The conference is a platform to start a discussion of topics that really matter to you. We ensure that
                your voice will be amplified and heard.{' '}
              </Text>
              <Text>
                Connect, learn and share with a diverse group of movers and shakers committed to the empowerment of
                refugees.{' '}
              </Text>
              <Button to="https://amplifynow.typeform.com/to/JgsqG9" title="Register now" />
            </header>
          </Column>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Section05;
