import styled from '@emotion/styled';
import { css } from '@emotion/core';

const wrapperStyles = ({ theme: { color } }) => css`
  padding: 50px 0;
  background: ${color.white};
`;

const listStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  list-style: none;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  ${breakpoint.fromPhoneMedium} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  ${breakpoint.fromTablet} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
  }
`;
const itemStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 0 15px;
  ${breakpoint.fromTablet} {
    padding: 0 30px;
  }

  &:nth-of-type(4) {
    transform: translateY(-20px);
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const List = styled('ul')(listStyles);
export const Item = styled('li')(itemStyles);
