import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from './KironLogo.style';
import logo from '../../images/kiron-logo.svg';
import logoWhite from '../../images/kiron-logo-invert.svg';

const KironLogo = ({ size, withLink, colorKind, ...props }) => {
  if (withLink) {
    return (
      <a href="https://kiron.ngo/" target="_blank" rel="noopener noreferrer">
        <Logo
          src={colorKind === 'light' ? logoWhite : logo}
          alt="Logo of Kiron Higher Education"
          size={size}
          {...props}
        />
      </a>
    );
  }

  return (
    <Logo src={colorKind === 'light' ? logoWhite : logo} alt="Logo of Kiron Higher Education" size={size} {...props} />
  );
};

KironLogo.propTypes = {
  size: PropTypes.number,
  withLink: PropTypes.bool,
  colorKind: PropTypes.oneOf(['light', 'dark']),
};

KironLogo.defaultProps = {
  size: 75,
  withLink: true,
  colorKind: 'dark',
};

export default KironLogo;
