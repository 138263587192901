import styled from '@emotion/styled';
import { css } from '@emotion/core';

const bodyStyles = css`
  padding: 15px;
`;

/**
 * Component style declarations
 */
export const Description = styled('div')(bodyStyles);
