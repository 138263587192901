/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Container, Grid, Text, Button } from '../../components';
import { Wrapper, Column, Header, Title } from './Section04.style';
import clapIcon from '../../images/clap.svg';

const Section04 = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <Column>
            <img src={clapIcon} />
            <Header>
              <Title element="h2">Become a sponsor</Title>
              <Text>
                We partner with companies, organisations and institutions that seek to empower refugees around the
                world. You will be recognized as one of the founding partners of the first global virtual refugee
                conference.
              </Text>
              <Button to="mailto:kate.muwoki@kiron.ngo" title="Contact us" buttonKind="light" />
            </Header>
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Section04;
