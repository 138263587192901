import { css } from '@emotion/core';

export const introContainerStyles = css`
  padding-top: 0px;
  padding-bottom: 50px;
  @media screen and (min-width: 36em) {
    padding-top: 150px;
    padding-bottom: 150px;
    align-items: center;
  }
`;
