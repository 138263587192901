import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading } from '../../components';

const wrapperStyles = ({ theme: { color } }) => css`
  padding: 5px 0;
  background: ${color.white};
`;

const contentStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-gap: 0 15px;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  ${breakpoint.toTablet} {
    max-width: calc(100% - 30px);
  }
  ${breakpoint.fromTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
`;

const columnStyles = ({
  index,
  theme: {
    grid: { breakpoint },
  },
}) => css`
  grid-row: ${index === 1 ? '1 / span 2' : index === 2 ? 1 : 2};
  ${breakpoint.fromTablet} {
    grid-column: ${index === 1 ? 1 : index === 2 ? 2 : 3};
    grid-row: none;
  }
`;

const imageWrapperStyles = css`
  max-width: 125px;
  margin: 0 auto;
`;

const titleStyles = css`
  margin: 0;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Content = styled('div')(contentStyles);
export const Column = styled('div')(columnStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
export const Title = styled(Heading)(titleStyles);
