import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, IFrame } from './EmbedVideo.style';

const EmbedVideo = ({ aspectRatio, videoID }) => {
  const generateVideoEmbed = url => {
    const isYoutube = url.indexOf('?v=') > -1;

    if (isYoutube) {
      const urlId = url.split('?v=')[1];

      return `https://www.youtube.com/embed/${urlId}`;
    } else {
      return `https://player.vimeo.com/video/${url}`;
    }
  };

  return (
    <Wrapper aspectRatio={aspectRatio}>
      <IFrame
        src={generateVideoEmbed(videoID)}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  );
};

EmbedVideo.propTypes = {
  aspectRatio: PropTypes.oneOf(['16-9', '4-3']),
  videoID: PropTypes.string.isRequired,
};

EmbedVideo.defaultProps = {
  aspectRatio: '16-9',
};

export default EmbedVideo;
