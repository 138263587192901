import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Wrapper,
  CardWrapper,
  Button,
  LiveButton,
  Header,
  Title,
  Date,
  Description,
  PresenterList,
  Presenter,
  ImageContainer,
  SateliteLabel,
} from './ProgramCard.style';
import { Copy } from '../Cards.style';
import satelliteIcon from '../../../images/satellite.svg';

const ProgramCard = ({
  title,
  shortDescription,
  presenter,
  date,
  isLive,
  isCompleted,
  isSatelite,
  slug,
  image,
  programStreamURL,
}) => (
  <Wrapper>
    <ImageContainer isCompleted={isCompleted}>
      {isSatelite && (
        <SateliteLabel isLive={isLive} isCompleted={isCompleted}>
          <img src={satelliteIcon} />
          Satellite Event
        </SateliteLabel>
      )}
      {isLive && !isCompleted && <LiveButton to={programStreamURL} title="Live Now" />}
      <Img alt={image.description} fluid={image.fluid} />
    </ImageContainer>
    <CardWrapper>
      <Header isCompleted={isCompleted}>
        <Title>{title}</Title>
        <Date>{date} CET</Date>
        <PresenterList>
          {presenter.map((item, i) => (
            <li key={item.name}>
              <Presenter>{`${item.name} ${i + 1 < presenter.length ? '•' : ''} `}</Presenter>
            </li>
          ))}
        </PresenterList>
      </Header>
      <Description>
        <Copy>{shortDescription}</Copy>
        <footer>
          {!isCompleted && (
            <Button
              isLive={isLive}
              buttonKind="light"
              buttonSize="mini"
              to={programStreamURL}
              title={isLive ? 'Join Live Stream' : 'Join stream'}
            />
          )}
          <Button buttonKind="light" buttonSize="mini" to={`/program/${slug}`} title="Learn more" />
        </footer>
      </Description>
    </CardWrapper>
  </Wrapper>
);

ProgramCard.defaultProps = {
  isSatelite: false,
};

ProgramCard.propTypes = {
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  presenter: PropTypes.array.isRequired,
  date: PropTypes.any.isRequired,
  isLive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isSatelite: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  programStreamURL: PropTypes.string.isRequired,
};

export default ProgramCard;
