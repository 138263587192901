import styled from '@emotion/styled';
import { css } from '@emotion/core';

const logoStyles = ({ size }) => css`
  display: block;
  width: ${size}px;
`;

/**
 * Component style declaration
 */
export const Logo = styled('img')(logoStyles);
