/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Text, Button, Carousel, ImpactSolutionCard } from '../../components';
import { Wrapper, Content, Column, ImageWrapper, Title, ImpactSolutionsWrapper } from './Section06.style';

const Section05 = () => {
  const pageQueries = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "section-06.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobileImage: file(relativePath: { eq: "section-06.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allContentfulSubmissions {
        edges {
          node {
            id
            slug
            title
            region
            submissionLink
            videoID
            shortDescription
          }
        }
      }
    }
  `);

  const imageSources = [
    {
      ...pageQueries.desktopImage.childImageSharp.fixed,
      media: `(min-width: 834.98px)`,
    },
    {
      ...pageQueries.mobileImage.childImageSharp.fixed,
      media: `(max-width: 834.98px)`,
    },
  ];

  const submissionsArray = pageQueries.allContentfulSubmissions.edges;
  const submissionsArrayFiltered = submissionsArray.filter(
    article => article.node.slug !== 'how-to-create-a-submission'
  );
  const carouselItems = submissionsArrayFiltered.map(submission => {
    const { node } = submission;

    return (
      <ImpactSolutionCard
        key={node.id}
        title={node.title}
        region={node.region}
        description={node.shortDescription}
        slug={node.slug}
        videoURL={node.videoID}
        solutionURL={node.submissionLink}
      />
    );
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <Column index={2}>
            <header>
              <Title element="h2">Win our IMPACT PRIZE</Title>
              <Text>
                Do you have an innovative solution that elevates learning, is inclusive to all and empowers refugees?
                You need support to make your idea even more successful, increase media visibility, access networks and
                mentorship? Submit your application today.
              </Text>
              <Text>Deadline for submission of ideas is 4th of June!</Text>
              <Button to="https://forms.gle/5HgYW8iEyDCmopYt9" title="Apply noW" />
            </header>
          </Column>
          <Column index={3}>
            <ImageWrapper>
              <Img fixed={imageSources} />
            </ImageWrapper>
          </Column>
        </Content>
      </Container>
      <ImpactSolutionsWrapper>
        <Container>
          <Title element="h2">IMPACT Solutions</Title>
          <Carousel slides={[carouselItems]} />
        </Container>
      </ImpactSolutionsWrapper>
    </Wrapper>
  );
};

export default Section05;
