import styled from '@emotion/styled';
import { css } from '@emotion/core';

const navStyles = () => css`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
`;

const listStyles = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const listItemStyles = ({ isReavealed, count }) => css`
  display: block;
  transition: 0.2s all ease-in-out;
  position: absolute;
  bottom: ${isReavealed ? count * 60 : 0}px;
  opacity: ${isReavealed ? 1 : 0};
`;

const listItemTriggerStyles = () => css`
  display: block;
  position: relative;
  z-index: 6;
`;

const shareButtonStyles = ({ theme }) => css`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  outline: none;
  border: 2px solid ${theme.color.white};
  background: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

/**
 * Component style declaration
 */
export const Nav = styled('nav')(navStyles);
export const List = styled('ul')(listStyles);
export const ListItem = styled('li')(listItemStyles);
export const ListItemTrigger = styled('li')(listItemTriggerStyles);
export const ShareButtonStyles = styled('button')(shareButtonStyles);
