/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '../../components';
import { Wrapper, Content, Column, ImageWrapper, Title } from './Section02.style';

const Section02 = () => {
  const data = useStaticQuery(graphql`
    query {
      section02Image: file(relativePath: { eq: "section-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Container>
        <Content>
          <Column index={1}>
            <ImageWrapper>
              <Img fluid={data.section02Image.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
            </ImageWrapper>
          </Column>
          <Column index={2}>
            <header>
              <Title element="h3" size="h1">
                7500+
              </Title>
              <span>Total participants</span>
            </header>
          </Column>
          <Column index={3}>
            <header>
              <Title element="h3" size="h1">
                83
              </Title>
              <span>Cities</span>
            </header>
          </Column>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Section02;
