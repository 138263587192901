import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Heading } from '../../components';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  padding: 200px 0 80px;
  ${breakpoint.fromTablet} {
    padding: 200px 0;
  }
`;

const columnStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  grid-template-rows: 110px 1fr;
  justify-items: center;
  ${breakpoint.toPhoneMedium} {
    margin-bottom: 30px;
  }
`;

const headerStyles = css`
  text-align: center;
  display: grid;

  a {
    margin: auto auto 0;
    width: max-content;
  }
`;

const titleStyles = css`
  margin: 0 auto 10px;
  max-width: 300px;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const Column = styled('div')(columnStyles);
export const Header = styled('header')(headerStyles);
export const Title = styled(Heading)(titleStyles);
