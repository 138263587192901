import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link as GatsbyLink } from 'gatsby';
import Heading from '../Heading';

const wrapperStyles = ({ theme: { color } }) => css`
  text-align: left;
  background: ${color.white};
`;

const headerStyles = css`
  padding: 15px 15px 0;
`;

const headingStyles = css`
  margin: 0;
`;

const textStyles = ({ theme: { color } }) => css`
  color: ${color.blueGray600};
  margin: 0 0 15px;
`;

const linkStyles = ({ theme: { color } }) => css`
  color: ${color.black};
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  &:hover,
  &:active,
  &:visited {
    color: ${color.black};
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('article')(wrapperStyles);
export const Header = styled('header')(headerStyles);
export const Title = styled(Heading)(headingStyles);
export const Copy = styled('p')(textStyles);
export const Link = styled(GatsbyLink)(linkStyles);
export const Hyperlink = styled('a')(linkStyles);
