import { useState } from 'react';
import debounce from './debounce';
import useMountEffect from './useMountEffect';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = debounce(() => {
    setWindowSize(getSize());
  }, 150);

  useMountEffect(() => {
    if (!isClient) {
      return false;
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return windowSize;
}

export default useWindowSize;
