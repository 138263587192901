import styled from '@emotion/styled';
import { css } from '@emotion/core';

const wrapperStyles = css`
  position: relative;
`;

const listStyles = css`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const listItemStyles = ({ isVisible }) => css`
  display: ${isVisible ? 'block' : 'none'};
  @media screen and (min-width: 40.625em) {
    width: calc(33.3333% - 30px);
    margin: 0 15px;
  }
`;

const dotStyles = ({ isActive }) => css`
  background: ${isActive ? '#fff' : 'rgba(255, 255, 255, 0.6)'};
  width: 8px;
  padding: 0;
  height: 8px;
  display: inline-block;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.15s all ease-in-out;

  &:hover {
    background: #fff;
  }
`;

const dotListStyles = css`
  @media screen and (min-width: 40.625em) {
    display: none;
  }
`;

const arrowButtonStyles = css`
  display: none;
  position: absolute;
  left: initial;
  right: -15px;
  top: 0;
  bottom: 0;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  @media screen and (min-width: 40.625em) {
    display: block;
  }
`;

const arrowPreviousButtonStyles = ({ side }) =>
  side === 'left' &&
  css`
    left: -15px;
    right: initial;
    transform: rotate(180deg);
  `;

/**
 * Component style declarations
 */
export const Wrapper = styled('section')(wrapperStyles);
export const List = styled('ul')(listStyles);
export const ListItem = styled('li')(listItemStyles);
export const DotList = styled('div')(dotListStyles);
export const Dot = styled('button')(dotStyles);
export const ArrowButtonStyles = styled('button')(arrowButtonStyles, arrowPreviousButtonStyles);
