/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Container, Grid, Text, Button } from '../../components';
import { Wrapper, Column, Header, Title } from './Section03.style';
import computerIcon from '../../images/computer.svg';
import microphoneIcon from '../../images/microphone.svg';

const Section03 = () => {
  return (
    <Wrapper id="section-03">
      <Container>
        <Grid gridKind="half">
          <Column>
            <img src={computerIcon} />
            <Header>
              <Title element="h2">Organise a satellite event</Title>
              <Text>
                A conference organiser volunteers to run a satellite event in their local community on the 19th and 20th
                of June 2020 around the conference theme with guidance from Kiron.
              </Text>
              <Button to="https://donorrelations.typeform.com/to/KbkRdl" title="Organize" />
            </Header>
          </Column>
          <Column>
            <img src={microphoneIcon} />
            <Header>
              <Title element="h2">Become a speaker</Title>
              <Text>
                We invite experts and leaders to enrich the discussion by presenting a related topic during a live
                conference session or alternatively pre recorded.
              </Text>
              <Button to="https://donorrelations.typeform.com/to/fS3fV8" title="Apply now" />
            </Header>
          </Column>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Section03;
